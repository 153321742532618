<template>
    <div>
      <div class="user-data">
        <div class="title hidden-sm-and-down">
          My Account
        </div>
        <div class="content-form">
          <el-form :rules="userInfoRules" :model="userInfo" class="p7-form"  ref="userInfoForm" label-width="2.8rem" label-position="left">
            <p class="header-sign">Please fill out the following information: </p>
            <el-form-item label="Profile Photo"  class="align-center" prop="photo">
              <el-upload class="avatar-uploader"
                         action="/FH-WEB/web/savefile.do"
                         :before-upload="beforeAvatarUpload"
                         :show-file-list="false" :on-success="userPhoto">
                <img v-if="userInfo.photo" :src="userInfo.photo" class="avatar">
                <div v-else>
                  <i class="el-icon-upload avatar-uploader-icon"></i>
                  <div class="el-upload__text">Click "Upload"</div>
                </div>
              </el-upload>
              <span class="upload-tip">Pictures smaller than 2M(JPG, GIF, PNG, BMP) with recommended size of 360*360px</span>
            </el-form-item>
            <el-form-item label="User Name"  prop="name">
              <el-input v-model="userInfo.name"  show-word-limit placeholder="No more than 24 words" maxlength="24"></el-input>
            </el-form-item>
            <el-form-item label="Phone Number" class="clear-indent" prop="phone">
              <span class="old-phone" >{{userInfo.phone}}</span>
<!--              <el-input type=""  v-model="userInfo.phone" maxlength="11" style="width: 200px"></el-input>-->
              <span class="change-phone" @click="changePhone">{{userInfo.phone == undefined || userInfo.phone == null || userInfo.phone == ''?'Binding phone':'Change Phone Number'}}</span>
<!--              <el-button type="success"  icon="el-icon-check" class="phone-btn" circle></el-button>-->
            </el-form-item>
            <el-form-item prop="email" class="clear-indent" label="Email">
                        <span class="old-email">{{userInfo.email}}</span>
              <el-button class="code-btn" @click="emailCode" :style="{'margin-left':userInfo.email!=''?'0.25rem':''}">{{userInfo.email == undefined || userInfo.email == null || userInfo.email == ''?'Verify now':'Change Email'}}</el-button>
            </el-form-item>
            <el-form-item  label="Region" prop="area">
              <div v-show="!areaSelShow">
                <span class="old-email">{{oldArea}}</span>
                <el-button class="code-btn" @click="changeArea" :style="{'margin-left':oldArea!=''?'0.25rem':''}">Re-edit</el-button>
              </div>
<!--              <v-distpicker style="display: inline-block" v-show="areaSelShow" hide-area @selected="areaSel"></v-distpicker>-->
              <div>
                <el-col :span="14">
                  <el-input v-show="areaSelShow" v-model="newArea"></el-input>
                </el-col>
                <el-col :span="10">
                  <el-button class="code-btn" @click="backArea" style="margin-left:0.25rem" v-show="oldArea!=''&& areaSelShow">Cancel</el-button>
                </el-col>
              </div>
            </el-form-item>
            <el-form-item  label="Address" class="clear-indent" prop="address">
              <el-input show-word-limit maxlength="50" v-model="userInfo.address" clearable></el-input>
            </el-form-item>
            <el-form-item :rules="[{required: true}]" label="Authentication">
              <drag-verify
                :width="codeWidth"
                :height="44"
                handlerIcon='el-icon-d-arrow-right'
                successIcon='el-icon-check'
                text="Drag the slider to the right"
                success-text="Successful"
                background="#ddd"
                progress-bar-bg="#2ACBF8"
                text-size="0.3rem"
                :circle="false"
                @passcallback="passcall">
              </drag-verify>
            </el-form-item>
            <el-form-item class="text-center">
              <el-button type="success" @click="userinfoSumbit">Submit</el-button>
            </el-form-item>
          </el-form>
        </div>
      </div>
      <div class="popup-box" v-show="popupShow">
        <div class="popup-card">
          <div class="title" v-show="popupStep === 1 || popupStep === 2 || popupStep === 3">Change Phone Number</div>
          <div class="title" v-show="popupStep === 4 || popupStep === 5">Verify email</div>
          <div v-show="popupStep === 1" class="phone-con1">
            <p class="sign" style="text-align: left">We’ll send the security code to the original registered phone number</p>
            <div class="phone-num">{{userInfo.phone}}</div>
            <div class="btn">
              <el-button @click="getOphoneCode" :disabled="OphoneCode.disabled" style="border-radius: 0;padding: 0.2rem 0.5rem">{{OphoneCode.show}}</el-button>
            </div>
            <el-form label-position="left" :model="phonecon1" ref="phonecon1Form" :rules="phonecon1Rules" class="phonecon1-form">
              <el-form-item  label="Enter verification code"  prop="code">
                <el-input oninput="if(value.length>6)value=value.slice(0,6)" v-model="phonecon1.code" type="number"></el-input>
              </el-form-item>
              <el-form-item>
                <el-button @click="closePopup">Cancel</el-button>
                <el-button @click="newStep(2)">Next</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-show="popupStep === 2" class="phone-con2">
            <p class="sign" style="text-align: left">{{userInfo.phone == ''?'Verify new phone number':'Original registered phone number Successful'}}</p>
            <el-form label-position="left" :model="phonecon2" ref="phonecon2Form" :rules="phonecon2Rules" class="phonecon2-form">
              <el-form-item label="Please enter the new phone number:" prop="newPhone" class="new-phone">
                <el-input class="area-num" v-model="phonecon2.area" type="number">
                  <template slot="prepend">+</template>
                </el-input>
                <el-input class="phone-num" v-model="phonecon2.newPhone" type="number"></el-input>
              </el-form-item>
              <el-form-item label="Please enter the security code you've received on your new phone:" prop="code" class="new-code">
                <el-input v-model="phonecon2.code" type="number" oninput="if(value.length>6)value=value.slice(0,6)"></el-input>
                <el-button class="get-code" :disabled="NphoneCode.disabled" @click="getNphoneCode">{{NphoneCode.show}}</el-button>
              </el-form-item>
              <el-form-item class="btn">
                <el-button @click="closePopup">Cancel</el-button>
                <el-button @click="newStep(3)">Complete validation</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-show="popupStep === 3" class="phone-con3">
            <i class="el-icon-circle-check"></i>
            <div class="txt">
              <p>You have changed the registered phone number</p>
              <p>Login again <span> with the new phone number</span>.</p>
            </div>
            <button class="close" @click="complete(1)">Close</button>
          </div>
          <div v-show="popupStep === 4" class="email-con1">
            <el-form label-position="left" :model="emailcon1" ref="emailcon1Form" :rules="emailcon1Rules" class="emailcon1-form">
              <el-form-item label="Please enter the Email Address for verification:" prop="email" class="new-email">
                <el-input class="phone-num" v-model="emailcon1.email"></el-input>
              </el-form-item>
              <el-form-item label="Enter verification code:" prop="code" class="new-code">
                <el-input type="number" v-model="emailcon1.code" oninput="if(value.length>6)value=value.slice(0,6)"></el-input>
                <el-button class="get-code" :disabled="NemailCode.disabled" @click="getEmailCode">{{NemailCode.show}}</el-button>
              </el-form-item>
              <el-form-item class="btn">
                <el-button @click="closePopup">Cancel</el-button>
                <el-button @click="newStep(5)">Complete validation</el-button>
              </el-form-item>
            </el-form>
          </div>
          <div v-show="popupStep === 5" class="phone-con3">
            <i class="el-icon-circle-check"></i>
            <div class="txt">
              <p>You have successfully authenticated your Email Address</p>
              <p>Login by <span> the email</span>.</p>
            </div>
            <button class="close" @click="complete(2)">Close</button>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
import qs from 'qs'
import { getUserData, saveUserinfo, editEmail, phoneLogin, editPhone, checkemail } from '../../api'
import { testemail2, phoneCode2 } from '../../api-en'

import VDistpicker from 'v-distpicker'
import dragVerify from 'vue-drag-verify'

export default {
  name: 'cityG7',
  data () {
    return {
      temNum: '',
      temEmail: '',
      codeWidth: 0,
      OphoneCode: {
        show: 'Get verification code',
        disabled: false,
        timer: null,
        count: ''
      },
      NphoneCode: {
        show: 'Get verification code',
        disabled: false,
        timer: null,
        count: ''
      },
      NemailCode: {
        show: 'Get verification code',
        disabled: false,
        timer: null,
        count: ''
      },
      popupShow: false,
      popupStep: 4,
      emailCodeNum: '',
      oldphoneCode: '',
      newphoneCode: '',
      localUserInfo: '',
      oldArea: '',
      newArea: '',
      areaSelShow: false,
      userInfo: {
        photo: '',
        name: '',
        phone: '',
        email: '',
        area: '',
        address: '',
        test: false
      },
      userInfoRules: {
        name: [
          { required: true, message: 'Please enter the User Name', trigger: 'blur' }
        ],
        phone: [
          { required: false, message: 'Please enter the phone number', trigger: 'blur' }
        ],
        area: [
          { required: true, message: 'Please select the region', trigger: 'change' }
        ],
        photo: [
          { required: true, message: 'Incorrect email address, please try again!', trigger: 'change' }
        ]
        // email: [
        //   { type: 'email', message: '请输入正确的邮箱地址', trigger: ['blur', 'change'] }
        // ]
      },
      phonecon1: {
        code: ''
      },
      phonecon2: {
        area: '86',
        newPhone: '',
        code: ''
      },
      emailcon1: {
        email: '',
        code: ''
      },
      phonecon1Rules: {
        code: [
          { required: true, message: 'Please enter the security code', trigger: 'blur' }
        ]
      },
      phonecon2Rules: {
        newPhone: [
          { required: true, message: 'Enter the new phone number', trigger: 'blur' }
        ],
        code: [
          { required: true, message: 'Please enter the security code', trigger: 'blur' }
        ]
      },
      emailcon1Rules: {
        email: [
          { type: 'email', required: true, message: 'Email address incorrect, please try again!', trigger: ['blur', 'change'] }
        ],
        code: [
          { required: true, message: 'Please enter the security code', trigger: 'blur' }
        ]
      }
    }
  },
  inject: ['reload'],
  methods: {
    changeArea () {
      this.areaSelShow = true
    },
    beforeAvatarUpload (file) {
      const isTYPE = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isTYPE) {
        this.$message({
          showClose: true,
          message: 'Incorrect format. Please upload a JPG or PNG file.',
          type: 'error',
          center: true
        })
      }
      if (!isLt2M) {
        this.$message({
          showClose: true,
          message: 'The image should not be larger than 2MB!',
          type: 'error',
          center: true
        })
      }
      return isTYPE && isLt2M
    },
    passcall () {
      this.userInfo.test = true
    },
    userPhoto (res, file) {
      this.userInfo.photo = '/FH-WEB/' + res.result
    },
    changePhone () {
      if (this.userInfo.phone == '') {
        this.popupShow = true
        this.popupStep = 2
      } else {
        this.$confirm('Adding a new phone number will cancel the one already registered. Continue?', 'Tips', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
          center: true
        }).then(() => {
          this.popupShow = true
          this.popupStep = 1
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Clear all the changes',
            center: true
          })
        })
      }
    },
    emailCode () {
      if (this.userInfo.email == '') {
        this.popupShow = true
        this.popupStep = 4
      } else {
        this.$confirm('Adding a new email will cancel the one already registered. Continue?', 'Tips', {
          confirmButtonText: 'Confirm',
          cancelButtonText: 'Cancel',
          type: 'warning',
          center: true
        }).then(() => {
          this.popupShow = true
          this.popupStep = 4
        }).catch(() => {
          this.$message({
            type: 'info',
            message: 'Cancel',
            center: true
          })
        })
      }
    },
    areaSel (i) {
      // console.log(i)
      this.userInfo.area = i.province.value + i.city.value
      console.log(this.userInfo.area)
    },
    userinfoSumbit () {
      console.log(this.localUserInfo)
      if (this.areaSelShow) {
        this.userInfo.area = this.newArea
      }
      this.$refs.userInfoForm.validate((valid) => {
        if (valid) {
          if (this.userInfo.test) {
            let data = {
              SPONSOR_ID: this.localUserInfo.userId,
              NAME: this.userInfo.name,
              PHONE: this.userInfo.phone,
              EMAIL: this.userInfo.email,
              AREA: this.userInfo.area,
              ADDRESS: this.userInfo.address,
              IMAGE: this.userInfo.photo
            }
            saveUserinfo(qs.stringify(data)).then((res) => {
              console.log(res)
              if (res.status === 200) {
                this.$message({
                  showClose: true,
                  message: 'Save successfully!',
                  type: 'success',
                  center: true
                })
                this.reload()
              } else {
                this.$message({
                  showClose: true,
                  message: 'Save failed. Please try again!',
                  type: 'error',
                  center: true
                })
              }
            })
          } else {
            this.$message({
              showClose: true,
              message: 'Please finish security verification',
              type: 'error',
              center: true
            })
          }
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    newStep (i) {
      if (i === 2) {
        this.$refs.phonecon1Form.validate((valid) => {
          if (valid) {
            if (this.oldphoneCode === '') {
              this.$message({
                showClose: true,
                message: 'Please get verification code',
                type: 'error',
                center: true
              })
            } else {
              if (this.contrastMd5(this.phonecon1.code, this.oldphoneCode)) {
                this.popupStep = i
              } else {
                this.$message({
                  showClose: true,
                  message: 'Incorrect security code',
                  type: 'error',
                  center: true
                })
              }
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else if (i === 3) {
        this.$refs.phonecon2Form.validate((valid) => {
          if (valid) {
            if (this.newphoneCode === '') {
              this.$message({
                showClose: true,
                message: 'Please get verification code',
                type: 'error',
                center: true
              })
            } else {
              if (this.contrastMd5(this.phonecon2.code, this.newphoneCode)) {
                if (this.temNum === this.phonecon2.newPhone) {
                  this.popupStep = i
                  this.userInfo.phone = this.phonecon2.newPhone
                } else {
                  this.$message({
                    showClose: true,
                    message: 'The phone number you entered is inconsistent with the one that receives the security code',
                    type: 'error',
                    center: true
                  })
                }
              } else {
                this.$message({
                  showClose: true,
                  message: 'Incorrect security code',
                  type: 'error',
                  center: true
                })
              }
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      } else if (i === 5) {
        this.$refs.emailcon1Form.validate((valid) => {
          if (valid) {
            // eslint-disable-next-line eqeqeq
            if (this.emailCodeNum != '') {
              if (this.contrastMd5(this.emailcon1.code, this.emailCodeNum)) {
                if (this.temEmail === this.emailcon1.email) {
                  this.popupStep = i
                  this.userInfo.email = this.emailcon1.email
                } else {
                  this.$message({
                    showClose: true,
                    message: 'The email you entered is inconsistent with the one that receives the security code',
                    type: 'error',
                    center: true
                  })
                }
              } else {
                this.$message({
                  showClose: true,
                  message: 'The code or email you entered is incorrect',
                  type: 'error',
                  center: true
                })
              }
            } else {
              this.$message({
                showClose: true,
                message: 'Get the security code first',
                type: 'error',
                center: true
              })
            }
          } else {
            console.log('error submit!!')
            return false
          }
        })
      }
    },
    closePopup () {
      this.popupShow = false
      // eslint-disable-next-line eqeqeq
      if (this.emailcon1.email != '') {
        this.emailcon1.email = ''
        this.emailcon1.code = ''
      }
      if (this.phonecon2.newPhone !== '') {
        this.phonecon1.code = ''
        this.phonecon2.area = '86'
        this.phonecon2.newPhone = ''
        this.phonecon2.code = ''
      }
      if (this.OphoneCode.disabled) {
        this.OphoneCode.show = 'Get verification code'
        clearInterval(this.OphoneCode.timer)
        this.OphoneCode.timer = null
        this.OphoneCode.disabled = false
      }
      if (this.NphoneCode.disabled) {
        this.NphoneCode.show = 'Get verification code'
        clearInterval(this.NphoneCode.timer)
        this.NphoneCode.timer = null
        this.NphoneCode.disabled = false
      }
      if (this.NemailCode.disabled) {
        this.NemailCode.show = 'Get verification code'
        clearInterval(this.NemailCode.timer)
        this.NemailCode.timer = null
        this.NemailCode.disabled = false
      }
    },
    complete (i) {
      let data = {
        SPONSOR_ID: this.localUserInfo.userId
      }
      if (i === 1) {
        data.PHONE = this.phonecon2.newPhone
        editPhone(qs.stringify(data)).then((res) => {
          if (res.status === 200) {
            this.userInfo.phone = this.phonecon2.newPhone
            this.popupShow = false
            this.phonecon1.code = ''
            this.phonecon2.area = '86'
            this.phonecon2.newPhone = ''
            this.phonecon2.code = ''
            this.OphoneCode.show = 'Get verification code'
            clearInterval(this.OphoneCode.timer)
            this.OphoneCode.timer = null
            this.OphoneCode.disabled = false
            this.NphoneCode.show = 'Get verification code'
            clearInterval(this.NphoneCode.timer)
            this.NphoneCode.timer = null
            this.NphoneCode.disabled = false
          }
        })
      } else if (i === 2) {
        data.EMAIL = this.emailcon1.email
        editEmail(qs.stringify(data)).then((res) => {
          if (res.status === 200) {
            this.userInfo.email = this.emailcon1.email
            this.popupShow = false
            this.emailcon1.email = ''
            this.emailcon1.code = ''
            this.NemailCode.show = 'Get verification code'
            clearInterval(this.NemailCode.timer)
            this.NemailCode.timer = null
            this.NemailCode.disabled = false
          }
        })
      }
    },
    getEmailCode () {
      this.NemailCode.show = 'Get verification code'
      const TIME_COUNT = 60
      if (this.emailcon1.email === '') {
        this.$message({
          showClose: true,
          message: 'Please first input the email',
          type: 'error',
          center: true
        })
      } else {
        checkemail(this.emailcon1.email).then((res) => {
          console.log(res.data[0]['count(EMAIL)'])
          if (res.data[0]['count(EMAIL)'] === 0) {
            if (!this.NemailCode.timer) {
              this.NemailCode.count = TIME_COUNT
              this.NemailCode.show = 'Get verification code'
              this.NemailCode.timer = setInterval(() => {
                if (this.NemailCode.count > 0 && this.NemailCode.count <= TIME_COUNT) {
                  this.NemailCode.count--
                  this.NemailCode.show = this.NemailCode.count + 's'
                  this.NemailCode.disabled = true
                } else {
                  this.NemailCode.show = 'Get verification code'
                  clearInterval(this.NemailCode.timer)
                  this.NemailCode.timer = null
                  this.NemailCode.disabled = false
                }
              }, 1000)
            }
            testemail2(this.emailcon1.email).then((res) => {
              console.log(res)
              if (res.status === 200) {
                this.$message({
                  showClose: true,
                  message: 'Security code sent successfully',
                  type: 'success',
                  center: true
                })
                this.emailCodeNum = res.data
                this.temEmail = this.emailcon1.email
              }
            })
          } else {
            this.$message({
              showClose: true,
              message: 'The Email Address has already been registered!',
              type: 'error',
              center: true
            })
          }
        })
      }
    },
    getOphoneCode () {
      this.OphoneCode.show = 'Get verification code'
      const TIME_COUNT = 60
      if (!this.OphoneCode.timer) {
        this.OphoneCode.count = TIME_COUNT
        this.OphoneCode.show = 'Get verification code'
        this.OphoneCode.timer = setInterval(() => {
          if (this.OphoneCode.count > 0 && this.OphoneCode.count <= TIME_COUNT) {
            this.OphoneCode.count--
            this.OphoneCode.show = this.OphoneCode.count + 's'
            this.OphoneCode.disabled = true
          } else {
            this.OphoneCode.show = 'Get verification code'
            clearInterval(this.OphoneCode.timer)
            this.OphoneCode.timer = null
            this.OphoneCode.disabled = false
          }
        }, 1000)
      }
      phoneCode2(this.userInfo.phone).then((res) => {
        if (res.status === 200) {
          this.$message({
            showClose: true,
            message: 'Security code sent successfully',
            type: 'success',
            center: true
          })
          this.oldphoneCode = res.data
        } else {
          this.$message({
            showClose: true,
            message: 'Security code send failed. Please refresh the page and try again ',
            type: 'error',
            center: true
          })
        }
      })
      // phoneLogin(this.userInfo.phone).then((res) => {
      //   if (res.data[0]['count(phone)'] == 0) {
      //   } else {
      //     this.$message({
      //       showClose: true,
      //       message: '该手机号码已被注册!',
      //       type: 'error',
      //       center: true
      //     })
      //   }
      // })
    },
    getNphoneCode () {
      this.NphoneCode.show = 'Get verification code'
      const TIME_COUNT = 60
      if (this.phonecon2.newPhone == '') {
        this.$message({
          showClose: true,
          message: 'Please input the phone number',
          type: 'error',
          center: true
        })
      } else {
        if (this.phonecon2.area === '86') {
          phoneLogin(this.phonecon2.newPhone).then((res) => {
            if (res.data.new['count(phone)'] == 0) {
              if (!this.NphoneCode.timer) {
                this.NphoneCode.count = TIME_COUNT
                this.NphoneCode.show = 'Get verification code'
                this.NphoneCode.timer = setInterval(() => {
                  if (this.NphoneCode.count > 0 && this.NphoneCode.count <= TIME_COUNT) {
                    this.NphoneCode.count--
                    this.NphoneCode.show = this.NphoneCode.count + 's'
                    this.NphoneCode.disabled = true
                  } else {
                    this.NphoneCode.show = 'Get verification code'
                    clearInterval(this.NphoneCode.timer)
                    this.NphoneCode.timer = null
                    this.NphoneCode.disabled = false
                  }
                }, 1000)
              }
              phoneCode2(this.phonecon2.newPhone).then((res) => {
                if (res.status === 200) {
                  this.$message({
                    showClose: true,
                    message: 'Security code sent successfully',
                    type: 'success',
                    center: true
                  })
                  this.newphoneCode = res.data
                  this.temNum = this.phonecon2.newPhone
                } else {
                  this.$message({
                    showClose: true,
                    message: 'Security code send failed. Please refresh the page and try again ',
                    type: 'error',
                    center: true
                  })
                }
              })
            } else {
              this.$message({
                showClose: true,
                message: 'The phone number has already been registered!',
                type: 'error',
                center: true
              })
            }
          })
        } else {
          let phoneNum = this.phonecon2.area + this.phonecon2.newPhone
          phoneLogin(phoneNum).then((res) => {
            console.log(res)
            if (res.data.new['count(phone)'] == 0) {
              if (!this.NphoneCode.timer) {
                this.NphoneCode.count = TIME_COUNT
                this.NphoneCode.show = 'Get verification code'
                this.NphoneCode.timer = setInterval(() => {
                  if (this.NphoneCode.count > 0 && this.NphoneCode.count <= TIME_COUNT) {
                    this.NphoneCode.count--
                    this.NphoneCode.show = this.NphoneCode.count + 's'
                    this.NphoneCode.disabled = true
                  } else {
                    this.NphoneCode.show = 'Get verification code'
                    clearInterval(this.NphoneCode.timer)
                    this.NphoneCode.timer = null
                    this.NphoneCode.disabled = false
                  }
                }, 1000)
              }
              phoneCode2(phoneNum).then((res) => {
                if (res.status === 200) {
                  this.$message({
                    showClose: true,
                    message: 'Security code sent successfully',
                    type: 'success',
                    center: true
                  })
                  this.newphoneCode = res.data
                  this.temNum = phoneNum
                } else {
                  this.$message({
                    showClose: true,
                    message: 'Security code send failed. Please refresh the page and try again ',
                    type: 'error',
                    center: true
                  })
                }
              })
            } else {
              this.$message({
                showClose: true,
                message: 'The phone number has already been registered!',
                type: 'error',
                center: true
              })
            }
          })
        }
      }
    },
    backArea () {
      this.areaSelShow = false
    },
    contrastMd5 (num, code) {
      let newNum = this.$md5(num)
      if (String(newNum) === code) {
        return true
      } else {
        return false
      }
    }
  },
  components: { VDistpicker, dragVerify },
  mounted () {
    if (/iphone|ipad|nokia|sony|ericsson|mot|samsung|sgh|lg|philips|panasonic|alcatel|lenovo|cldc|midp|wap|android|iPod/i.test(navigator.userAgent.toLowerCase())) {
      if ($('.p7-form').width() == 0) {
        this.$nextTick(() => {
          this.codeWidth = parseInt($('.content-form .header-sign')[6].clientWidth - 1)
        })
      } else {
        this.codeWidth = parseInt($('.p7-form').width())
      }
    } else {
      this.codeWidth = 300
    }
    let userInfo = JSON.parse(localStorage.getItem('userInfo'))
    this.localUserInfo = userInfo
    console.log(userInfo)
    if (userInfo.userId === '97d7581471354cf4a84ec06218da7ad2') {
      this.$confirm('To log in, please click the button below', 'Tips', {
        confirmButtonText: 'Confirm',
        type: 'warning',
        center: true,
        showCancelButton: false,
        showClose: false,
        closeOnPressEscape: false
      }).then(() => {
        if (sc.isSZSMT()) {
          console.log('在i深圳平台')
          this.$parent.linkIshenzhen()
        } else {
          console.log('不在i深圳平台内')
          this.$router.push('/LoginEN')
        }
      })
    } else {
      let data = {
        'SPONSOR_ID': userInfo.userId
      }
      getUserData(qs.stringify(data)).then((res) => {
        console.log(res)
        if (res.data.new.PHONE != undefined || res.data.new.PHONE != null) {
          this.userInfo.phone = res.data.new.PHONE
        }
        if (res.data.new.EMAIL != undefined || res.data.new.EMAIL != null) {
          this.userInfo.email = res.data.new.EMAIL
        }
        if (res.data.new.IMAGE != undefined || res.data.new.IMAGE != null) {
          this.userInfo.photo = res.data.new.IMAGE
        }
        if (res.data.new.NAME != undefined || res.data.new.NAME != null) {
          this.userInfo.name = res.data.new.NAME
        }
        if (res.data.new.ADDRESS != undefined || res.data.new.ADDRESS != null) {
          this.userInfo.address = res.data.new.ADDRESS
        }
        if (res.data.new.AREA != undefined || res.data.new.AREA != null) {
          this.oldArea = res.data.new.AREA
          this.userInfo.area = res.data.new.AREA
        } else {
          this.areaSelShow = true
        }
      })
    }
  }
}
</script>
<style lang="stylus" scoped src="../../assets/css/CityGe.styl"></style>
<style lang="stylus">
.G-container.en{
  .p7-form .el-form-item:nth-last-child(2) .el-form-item__label{
    line-height 1.6
    display flex
  }
  .el-button.is-disabled, .el-button.is-disabled:focus, .el-button.is-disabled:hover{
    color: #fff !important;
    background-color: #b3e19d !important;
    border-color: #b3e19d !important;
  }
  .content-form .upload-tip{
    position: absolute;
    width: max-content;
    top: 1.7rem;
    left: 3rem;
    color: #8d8d8d;
    font-size: 0.2592rem;
  }
  .code-btn{
    padding 0.2rem 0.7rem
    background #a5cd39
    color #fff
    border-radius 0
  }
  .popup-box{
    position  fixed
    display flex
    align-items center
    justify-content center
    width  100%
    height  100%
    top  0
    left  0
    background rgba(0,0,0,0.7)
    z-index  999
    .popup-card{
      width 9.6rem
      padding  0.65rem 0.37rem
      background  #fff
      .title{
        font-size 0.444rem
        padding-bottom 0.24rem
        padding-left 0.55rem
        border-bottom 1px solid #D4D4D4
        text-align left
        color #464646
      }
      .phone-con1{
        padding 0 0.55rem
        color #8D8D8D
        font-size 0.3rem
        .sign{
          margin-top 0.92rem
        }
        .btn{
          margin-top 0.37rem
          button{
            padding 0.15rem 0.5rem
            background #A6CE36
            color #fff
            border 1px solid #A6CE36
            cursor pointer
          }
        }
        .phonecon1-form{
          margin-top 0.7rem
          .el-form-item__label{
            font-size 0.3rem
            color #8D8D8D
          }
          .el-button{
            width 48%
            margin-top 0.5rem
            border-radius 0
            /*font-size 0.28rem*/
            &:nth-child(1){
              background #fff
              color #515555
              border 1px solid #C3C4C4
            }
            &:nth-child(2){
              color #fff
              background #A6CE36
              border 1px solid #A6CE36
            }
          }
        }
      }
      .phone-con2{
        padding 0 0.55rem
        color #8D8D8D
        font-size 0.3rem
        .sign{
          margin-top 0.74rem
        }
        .phonecon2-form{
          margin-top 0.7rem
          .el-form-item__label{
            font-size 0.3rem
            color #8D8D8D
            width 100%
          }
          .new-phone{
            .area-num{
              width:27%;
              margin-right: 3%;
              .el-input-group__prepend{
                border-right: 0;
                border-radius:0;
                padding:0 5px;
              }
            }
            .phone-num{
              width: 70%;
            }
          }
          .new-code{
            .el-form-item__label{
              display flex
              line-height 20px
              margin  20px 0 10px 0
            }
            .el-input{
              width 50%
            }
            .el-button{
              width 50%
              color #fff
              font-size 0.3rem
              background #a5cd39
              border-radius 0
              border 1px solid #a5cd39
              padding 0
              height 40px
              vertical-align bottom
            }
          }
          .btn{
            .el-button{
              width 48%
              margin-top 0.5rem
              border-radius 0
              font-size 0.3rem
              &:nth-child(1){
                background #fff
                color #515555
                border 1px solid #c2c2c2
              }
              &:nth-child(2){
                color #fff
                background #A6CE36
                border 1px solid #A6CE36
              }
            }
          }
        }
      }
      .phone-con3{
        padding 1rem 0
        i{
          font-size 1.5rem
          color #a5cd39
        }
        .txt{
          margin-top 0.3rem
          p{
            font-size 0.3rem
            color #8d8d8d
            margin-top 0.1rem
            span{
              color #f00
            }
          }
        }
        .close{
          margin-top 1rem
          padding 0.18rem 1.66rem
          background #a5cd39
          color #fff
          font-size 0.3rem
          border 1px solid #a5cd39
          cursor pointer
        }
      }
      .email-con1{
        padding 0 0.55rem
        color #8D8D8D
        font-size 0.3rem
        margin-top 0.92rem
        .el-form-item__label{
          font-size 0.3rem
          color #8D8D8D
          width 100%
        }
        .emailcon1-form{
          .new-code{
            .el-input{
              width 50%
            }
            .el-button{
              width 50%
              color #fff
              font-size 0.3rem
              background #a5cd39
              border-radius 0
              border 1px solid #a5cd39
              padding 0
              height 40px
              vertical-align bottom
            }
          }
          .btn{
            .el-button{
              width 48%
              margin-top 1rem
              border-radius 0
              font-size 0.3rem
              &:nth-child(1){
                background #fff
                color #515555
                border 1px solid #c2c2c2
              }
              &:nth-child(2){
                color #fff
                background #A6CE36
                border 1px solid #A6CE36
              }
            }
          }
        }
      }
    }
  }
}
@media screen and (max-width:990px){
  .G-container.en{
    .code-btn{
      padding 0.3rem 1rem
      float right
    }
  }
  .G-container.en .content-box .content .user-data{
    .el-button--success{
      width 100%
    }
    .content-form{
      .upload-tip {
        width: 4.2rem;
        top: 0.8rem;
        line-height: 0.5rem;
        left: 2.5rem;
      }
      .header-sign{
        font-size 0.35rem
        width 100%
        border-bottom 1px solid #e2e2e2
        border-top 1px solid #e2e2e2
      }
      .align-center{
        margin-top 0.5rem
        .el-form-item__label{
          width 3.5rem !important
        }
      }
      .el-form-item__label{
        width 100% !important
        float none
        display block
        line-height 40px
      }
      .el-form-item__content{
        width 100%!important
        margin-left 0!important
        .old-phone{
          display inline-block
          width 50%
          padding 0 15px
          text-decoration underline
        }
        .change-phone{
          padding: 0 .3rem;
          float right
          display inline-block
          background #bebebe
          color #fff
          text-align center
          margin-left 0
          text-decoration none
        }
        .old-email{
          padding 0 15px
        }
      }
    }
  }
}
</style>
